import dateStep3 from '../shared_required_steps/dateStep3';
import emailStep2 from '../shared_required_steps/emailStep2';
import guestStep4 from '../shared_required_steps/guestStep4';
import nameStep1 from '../shared_required_steps/nameStep1';

const properties = require('../../properties');

const REC = {
	vendorCategory: 'REC',
	tracking: properties.tracking.events.all,
	formName: 'REC',
	steps: [
		nameStep1,
		emailStep2,
		dateStep3,
		guestStep4,
		{
			metaData: {
				name: 'Events',
				stepNumber: 5,
				excludeFromStepCount: false,
				hideProgress: false,
				progressDescription: 'Events',
				liveValidate: true,
				noHtml5Validate: true,
				showErrorList: false,
				header: {
					showClose: true,
				},
			},
			schema: {
				description: 'step 5',
				type: 'object',
				properties: {
					events: {
						...properties.events.property,
						title: 'Which events do you plan to hold at this venue?',
						values: [
							{
								display: 'Rehearsal',
								value: { name: 'Rehearsal' },
							},
							{
								display: 'Ceremony',
								value: { name: 'Ceremony' },
							},
							{
								display: 'Reception',
								value: { name: 'Reception' },
							},
						],
						required: true,
					},
				},
			},
			ui: {
				'ui:rootFieldId': 'xo_rfq',
				events: {
					...properties.events.ui,
					classNames: 'col-xs-12',
				},
			},
		},
		{
			metaData: {
				name: 'Services',
				stepNumber: 6,
				excludeFromStepCount: false,
				hideProgress: false,
				progressDescription: 'Services',
				liveValidate: true,
				noHtml5Validate: true,
				showErrorList: false,
				header: {
					showClose: true,
				},
			},
			schema: {
				description: 'step 6',
				type: 'object',
				properties: {
					additionalServices: {
						...properties.additionalServices.property,
						title: 'What additional services might you be interested in?',
						values: [
							{
								display: 'Indoor area',
								value: { name: 'Indoor area' },
							},
							{
								display: 'Outdoor area',
								value: { name: 'Outdoor area' },
							},
							{
								display: 'Sound equipment',
								value: { name: 'Sound equipment' },
							},
							{
								display: 'Video equipment',
								value: { name: 'Video equipment' },
							},
							{
								display: 'Microphones',
								value: { name: 'Microphones' },
							},
							{
								display: 'Tables & chairs',
								value: { name: 'Tables & chairs' },
							},
							{
								display: 'Linens',
								value: { name: 'Linens' },
							},
							{
								display: 'Lighting',
								value: { name: 'Lighting' },
							},
							{
								display: 'Kitchen/Bar area',
								value: { name: 'Kitchen/Bar area' },
							},
							{
								display: 'On-site catering',
								value: { name: 'On-site catering' },
							},
							{
								display: 'A/V tech support',
								value: { name: 'A/V tech support' },
							},
							{
								display: 'Party planning services',
								value: { name: 'Party planning services' },
							},
							{
								display: 'Valet parking',
								value: { name: 'Valet parking' },
							},
						],
					},
				},
			},
			ui: {
				'ui:rootFieldId': 'xo_rfq',
				additionalServices: {
					...properties.additionalServices.ui,
					'ui:options': 'no-break',
					classNames: 'col-xs-12',
				},
			},
		},
		{
			metaData: {
				name: 'Budget',
				stepNumber: 7,
				excludeFromStepCount: false,
				hideProgress: false,
				progressDescription: 'Budget',
				liveValidate: true,
				noHtml5Validate: true,
				showErrorList: false,
				header: {
					showClose: true,
				},
			},
			schema: {
				description: 'step 7',
				type: 'object',
				properties: {
					budget: {
						...properties.budget.property,
						title: "What's your estimated budget for venue services?",
						values: [
							{
								display: 'Over $65,000',
								value: {
									minBudget: 65000,
									maxBudget: 0,
								},
							},
							{
								display: '$55,000 - $65,000',
								value: {
									minBudget: 55000,
									maxBudget: 65000,
								},
							},
							{
								display: '$45,000 - $55,000',
								value: {
									minBudget: 45000,
									maxBudget: 55000,
								},
							},
							{
								display: '$35,000 - $45,000',
								value: {
									minBudget: 35000,
									maxBudget: 45000,
								},
							},
							{
								display: '$25,000 - $35,000',
								value: {
									minBudget: 25000,
									maxBudget: 35000,
								},
							},
							{
								display: '$15,000 - $25,000',
								value: {
									minBudget: 15000,
									maxBudget: 25000,
								},
							},
							{
								display: '$5,000 - $15,000',
								value: {
									minBudget: 5000,
									maxBudget: 15000,
								},
							},
							{
								display: 'Less than $5,000',
								value: {
									minBudget: 1,
									maxBudget: 5000,
								},
							},
							{
								display: "I'm not sure yet",
								value: {
									minBudget: 0,
									maxBudget: 0,
								},
							},
						],
					},
				},
			},
			ui: {
				'ui:rootFieldId': 'xo_rfq',
				budget: {
					...properties.budget.ui,
					classNames: 'col-xs-12',
				},
			},
		},
		{
			metaData: {
				name: 'Add a personal note',
				stepNumber: 8,
				excludeFromStepCount: false,
				hideProgress: false,
				progressDescription: 'Add a personal note',
				liveValidate: true,
				noHtml5Validate: true,
				showErrorList: false,
				header: {
					showClose: true,
				},
				footer: {
					nextButton: {
						text: 'Submit',
					},
				},
			},
			schema: {
				description: 'step 8',
				type: 'object',
				properties: {
					aboutHeading: properties.aboutYourWedding.property,
					bodyLabel: {},
					body: properties.body.property,
					bluecard: properties.bluecard.property,
					VRMSelectionWidget: properties.VRMSelectionWidget.property,
					legalCopy: properties.legalCopy.property,
				},
			},
			ui: {
				'ui:rootFieldId': 'xo_rfq',
				aboutHeading: properties.aboutYourWedding.ui,
				bodyLabel: properties.bodyLabel.ui,
				body: properties.body.ui,
				bluecard: properties.bluecard.ui,
				VRMSelectionWidget: properties.VRMSelectionWidget.ui,
				legalCopy: properties.legalCopy.ui,
			},
		},
	],
	lastRequest: {
		metaData: properties.lastRequest.metadata,
		rows: [
			[
				{
					label: 'Name',
					type: 'literal',
					source: '${data.firstName} ${data.lastName}',
					id: 'rfq-summary-name',
				},
				{
					label: 'Email Address',
					type: 'literal',
					source: '${data.emailAddress}',
					id: 'rfq-summary-email',
				},
			],
			[
				{
					label: 'Wedding Date',
					type: 'literal',
					source: '${data.xo_date_picker.weddingDate}',
					id: 'rfq-summmary-wedding-date',
				},
				{
					label: 'Number of Guests',
					type: 'literal',
					source: '${data.guest_count.display}',
					id: 'rfq-summmary-guest-count',
				},
			],
			[
				{
					label: 'Flexible Wedding Date',
					type: 'bool',
					source: 'flexibleWeddingDate',
					id: 'rfq-summmary-flexible-wedding-date',
				},
				{
					label: 'Events Required',
					type: 'array',
					source: 'events',
					id: 'rfq-summmary-events',
				},
			],
			[
				{
					label: 'Additional Services Required',
					type: 'array',
					source: 'additionalServices',
					id: 'rfq-summmary-equipment-or-services',
				},
			],
			[
				{
					label: 'Budget',
					type: 'literal',
					source: '${data.budget.display}',
					id: 'rfq-summmary-budget',
				},
				{
					label: 'Quick Note',
					type: 'literal',
					source: '${data.body}',
					id: 'rfq-summmary-about-your-wedding',
				},
			],
			[
				{
					label: 'Attach your style results to your message',
					type: 'bool',
					source: 'bluecard',
					id: 'rfq-summary-bluecard',
				},
			],
		],
	},
};

export default REC;
