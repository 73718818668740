const properties = require('../../properties');

const guestCountStep4 = {
	metaData: {
		name: 'Guest Count',
		stepNumber: 4,
		excludeFromStepCount: false,
		hideProgress: false,
		progressDescription: 'Guest Count',
		liveValidate: true,
		noHtml5Validate: true,
		showErrorList: false,
		header: {
			title: 'How many guests do you plan to have?',
			showClose: true,
			icon: true,
		},
	},
	schema: {
		description: 'step 2',
		type: 'object',
		properties: {
			guest_count: properties.guestCount.property,
		},
	},
	ui: {
		guest_count: properties.guestCount.optimizelyUi,
	},
};

export default guestCountStep4;
