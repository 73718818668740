import dataRows from './last_request/dataRows';
import metadata from './last_request/metadata';
import properties from './last_request/properties';

const lastRequest = {
	metadata,
	properties,
	dataRows,
};

export default lastRequest;
