import { useAppSelector } from '@redux/hooks';
import { useMemo } from 'react';
import { FEATURE_FLAGS, useFeatureFlags } from '../../../feature-flags';

export const useStorefrontFeatureScannability = () => {
	const featureFlags = useFeatureFlags();
	const vendor = useAppSelector((state) => state.vendor.vendor);
	const shouldShowScannability = useMemo(() => {
		return Boolean(
			featureFlags[FEATURE_FLAGS.STOREFRONT_SCANNABILITY] && vendor?.isPaid,
		);
	}, [featureFlags[FEATURE_FLAGS.STOREFRONT_SCANNABILITY], vendor]);
	return shouldShowScannability;
};
