const property = {
	type: 'string',
	label: 'Email',
	format: 'email',
	required: true,
	maxLength: 149,
	messages: {
		required: 'Please provide a valid email address',
		format: 'Please provide a valid email address',
		maxLength: 'Email must be under 150 characters',
	},
};

const ui = {
	'ui:widget': 'email',
	'ui:placeholder': 'Email',
	classNames: 'col-sm-12',
};

const propertyName = 'emailAddress';

module.exports = {
	property,
	ui,
	propertyName,
};
