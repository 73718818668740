const property = {
	type: 'array',
};

const ui = {
	'ui:field': 'xo_multi_select_list',
	'ui:options': {
		displayErrors: false,
	},
	classNames: 'col-xs-12',
};

module.exports = {
	property,
	ui,
};
