import properties from './properties';

const dataRows = {
	nameEmail: [properties.name, properties.email],
	weddingDateGuestCount: [properties.weddingDate, properties.guestCount],
	venueNumberHours: [properties.venue, properties.numberOfHours],
	servicesNeeded: [properties.servicesNeeded],
	startTimeBudget: [properties.startTime, properties.budget],
	additionalServices: [properties.additionalServices],
	servicesRequired: [properties.servicesRequired],
	about: [properties.about],
	bluecard: [properties.bluecard],
};

export default dataRows;
