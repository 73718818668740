import dateStep3 from '../../shared_required_steps/dateStep3';
import emailStep2 from '../../shared_required_steps/emailStep2';
import guestStep4 from '../../shared_required_steps/guestStep4';
import nameStep1 from '../../shared_required_steps/nameStep1';

const properties = require('../../../properties');

const UNPAID_RFQ = {
	vendorCategory: 'UNPAID_RFQ',
	tracking: properties.tracking.events.all,
	formName: 'UNPAID_RFQ',
	steps: [
		nameStep1,
		emailStep2,
		dateStep3,
		guestStep4,
		{
			metaData: {
				name: 'Add a personal note',
				stepNumber: 5,
				excludeFromStepCount: false,
				hideProgress: false,
				progressDescription: 'Add a personal note',
				liveValidate: true,
				noHtml5Validate: true,
				showErrorList: false,
				header: {
					showClose: true,
				},
				footer: {
					nextButton: {
						text: 'Submit',
					},
				},
			},
			schema: {
				description: 'step 5',
				type: 'object',
				properties: {
					aboutHeading: properties.aboutYourWedding.property,
					bodyLabel: {},
					body: properties.body.property,
					bluecard: properties.bluecard.property,
					legalCopy: properties.legalCopy.property,
				},
			},
			ui: {
				'ui:rootFieldId': 'xo_rfq',
				aboutHeading: properties.aboutYourWedding.ui,
				bodyLabel: properties.bodyLabel.ui,
				body: properties.body.ui,
				bluecard: properties.bluecard.ui,
				legalCopy: properties.legalCopy.ui,
			},
		},
	],
	lastRequest: {
		metaData: properties.lastRequest.metadata,
		rows: [
			[
				{
					label: 'Name',
					type: 'literal',
					source: '${data.firstName} ${data.lastName}',
					id: 'rfq-summary-name',
				},
				{
					label: 'Email Address',
					type: 'literal',
					source: '${data.emailAddress}',
					id: 'rfq-summary-email',
				},
			],
			[
				{
					label: 'Wedding Date',
					type: 'literal',
					source: '${data.xo_date_picker.weddingDate}',
					id: 'rfq-summmary-wedding-date',
				},
				{
					label: 'Guest Count',
					type: 'literal',
					source: '${data.guest_count.display}',
					id: 'rfq-summmary-guest-count',
				},
			],
			[
				{
					label: 'Quick Note',
					type: 'literal',
					source: '${data.body}',
					id: 'rfq-summmary-about-your-wedding',
				},
			],
			[
				{
					label: 'Attach your style results to your message',
					type: 'bool',
					source: 'bluecard',
					id: 'rfq-summary-bluecard',
				},
			],
		],
	},
};

export default UNPAID_RFQ;
