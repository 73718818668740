const property = {
	type: 'object',
	label: 'Wedding Date',
	properties: {
		weddingDate: {
			type: 'string',
			required: true,
			messages: {
				required: 'Wedding Date is required',
			},
		},
	},
};

const ui = {
	'ui:field': 'xo_date_picker',
	classNames: 'col-xs-12 col-md-6',
};

const optimizelyUi = {
	'ui:field': 'xo_date_picker',
	classNames: 'col-xs-12',
};

const propertyName = 'xo_date_picker';

module.exports = {
	property,
	ui,
	optimizelyUi,
	propertyName,
};
