const properties = require('../../properties');

// This step, if included, is required & always step 5
// However, not all categories include the Venue step

const venueStep = {
	metaData: {
		name: 'Venue',
		stepNumber: 5,
		excludeFromStepCount: false,
		hideProgress: false,
		progressDescription: 'Venue',
		liveValidate: true,
		noHtml5Validate: true,
		showErrorList: false,
		header: {
			showClose: true,
		},
	},
	schema: {
		description: 'step 5',
		type: 'object',
		properties: {
			venue_picker: properties.venuePicker.property,
		},
	},
	ui: {
		'ui:rootFieldId': 'xo_rfq',
		venue_picker: properties.venuePicker.ui,
	},
};

export default venueStep;
