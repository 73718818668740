import dataRows from './last_request/dataRows';
import metadata from './last_request/metadata_message';
import properties from './last_request/properties';

const lastRequestMessage = {
	metadata,
	properties,
	dataRows,
};

export default lastRequestMessage;
