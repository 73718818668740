const property = {
	title: 'Do you have a venue picked out?',
	type: 'object',
	required: true,
	properties: {
		receptionVenue: {
			type: 'object',
			required: true,
			minProperties: 1,
			options: {
				venue: 'Yes, I have picked a venue',
				location: "No, I'm still looking for a venue",
			},
			messages: {
				required: 'Please let us know about your venue',
			},
		},
		requireCeremonyVenue: {
			type: 'bool',
			label: 'I also need services at a separate ceremony venue',
		},
	},
};

const ui = {
	'ui:field': 'xo_venue_picker',
	classNames: 'col-xs-12',
};

module.exports = {
	property,
	ui,
};
