const property = {
	label: 'Event Start Time',
	type: 'object',
	values: [
		{
			display: '12:00 PM',
			value: '12:00',
		},
		{
			display: '12:30 PM',
			value: '12:30',
		},
		{
			display: '1:00 PM',
			value: '13:00',
		},
		{
			display: '1:30 PM',
			value: '13:30',
		},
		{
			display: '2:00 PM',
			value: '14:00',
		},
		{
			display: '2:30 PM',
			value: '14:30',
		},
		{
			display: '3:00 PM',
			value: '15:00',
		},
		{
			display: '3:30 PM',
			value: '15:30',
		},
		{
			display: '4:00 PM',
			value: '16:00',
		},
		{
			display: '4:30 PM',
			value: '16:30',
		},
		{
			display: '5:00 PM',
			value: '17:00',
		},
		{
			display: '5:30 PM',
			value: '17:30',
		},
		{
			display: '6:00 PM',
			value: '18:00',
		},
		{
			display: '6:30 PM',
			value: '18:30',
		},
		{
			display: '7:00 PM',
			value: '19:00',
		},
		{
			display: '7:30 PM',
			value: '19:30',
		},
		{
			display: '8:00 PM',
			value: '20:00',
		},
		{
			display: '8:30 PM',
			value: '20:30',
		},
		{
			display: '9:00 PM',
			value: '21:00',
		},
		{
			display: '9:30 PM',
			value: '21:30',
		},
		{
			display: '10:00 PM',
			value: '22:00',
		},
		{
			display: '10:30 PM',
			value: '22:30',
		},
		{
			display: '11:00 PM',
			value: '23:00',
		},
		{
			display: '11:30 PM',
			value: '23:30',
		},
		{
			display: '12:00 AM',
			value: '00:00',
		},
		{
			display: '12:30 AM',
			value: '00:30',
		},
		{
			display: '1:00 AM',
			value: '01:00',
		},
		{
			display: '1:30 AM',
			value: '01:30',
		},
		{
			display: '2:00 AM',
			value: '02:00',
		},
		{
			display: '2:30 AM',
			value: '02:30',
		},
		{
			display: '3:00 AM',
			value: '03:00',
		},
		{
			display: '3:30 AM',
			value: '03:30',
		},
		{
			display: '4:00 AM',
			value: '04:00',
		},
		{
			display: '4:30 AM',
			value: '04:30',
		},
		{
			display: '5:00 AM',
			value: '05:00',
		},
		{
			display: '5:30 AM',
			value: '05:30',
		},
		{
			display: '6:00 AM',
			value: '06:00',
		},
		{
			display: '6:30 AM',
			value: '06:30',
		},
		{
			display: '7:00 AM',
			value: '07:00',
		},
		{
			display: '7:30 AM',
			value: '07:30',
		},
		{
			display: '8:00 AM',
			value: '08:00',
		},
		{
			display: '8:30 AM',
			value: '08:30',
		},
		{
			display: '9:00 AM',
			value: '09:00',
		},
		{
			display: '9:30 AM',
			value: '09:30',
		},
		{
			display: '10:00 AM',
			value: '10:00',
		},
		{
			display: '10:30 AM',
			value: '10:30',
		},
		{
			display: '11:00 AM',
			value: '11:00',
		},
		{
			display: '11:30 AM',
			value: '11:30',
		},
	],
	required: true,
	minProperties: 1,
	messages: {
		required: 'Please provide the event start time',
	},
};

const ui = {
	'ui:field': 'xo_drop_down',
	'ui:placeholder': 'Event Start time',
	classNames: 'col-xs-12 col-md-6',
};

module.exports = {
	property,
	ui,
};
