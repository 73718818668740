const property = {
	type: 'boolean',
	required: false,
	bluecardRequired: true,
};

const ui = {
	'ui:widget': 'BluecardWidget',
	'ui:options': {
		label: "I'd like quotes from five similar vendors on the knot",
	},
	classNames: 'col-sm-12',
};

module.exports = {
	property,
	ui,
};
