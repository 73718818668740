const property = {
	type: 'string',
	label: 'Terms of Service',
};

const ui = {
	'ui:widget': 'LegalCopy',
	'ui:options': {
		footer_tos: true,
	},
	classNames: 'rfq-step notes-step col-xs-12',
	'ui:placeholder':
		'By clicking ‘Submit’, you agree that your information will be shared with the vendor and used to create an account on TheKnot.com. Please see our Privacy Policy and Terms of Use for details.',
};

const propertyName = 'legalCopy';

module.exports = {
	property,
	ui,
	propertyName,
};
