const property = {
	type: 'string',
	label: 'Last Name',
	required: true,
	maxLength: 149,
	minLength: 2,
	messages: {
		required: 'Last Name is required',
		minLength: 'Last Name must be at least 2 characters',
		maxLength: 'Last Name must be under 150 characters',
	},
};

const ui = {
	'ui:widget': 'text',
	'ui:placeholder': 'Last Name',
	'ui:options': {
		col: {
			sm: '6',
		},
	},
	classNames: 'col-xs-12 col-sm-6',
};

const propertyName = 'lastName';

const lastName = {
	property,
	ui,
	propertyName,
};

export default lastName;
