const nameStep1 = {
	metaData: {
		name: 'Basic',
		stepNumber: 1,
		excludeFromStepCount: false,
		hideProgress: false,
		progressDescription: 'Name',
		liveValidate: true,
		noHtml5Validate: true,
		showErrorList: false,
	},
	schema: {
		description: 'step 2',
		type: 'object',
		properties: {
			lightHeading: {
				type: 'string',
				label:
					'Get the most accurate pricing and availability info by sharing some of your wedding details with this vendor.',
				text: 'Get the most accurate pricing and availability info by sharing some of your wedding details with this vendor.',
			},
			subHeading: {
				type: 'string',
				label: "What's your name?",
				text: "What's your name?",
			},
			firstName: {
				type: 'string',
				label: 'First Name',
				required: true,
				maxLength: 149,
				minLength: 2,
				messages: {
					required: 'First Name is required',
					minLength: 'First Name must be at least 2 characters',
					maxLength: 'First Name must be under 150 characters',
				},
			},
			lastName: {
				type: 'string',
				label: 'Last Name',
				required: true,
				maxLength: 149,
				minLength: 2,
				messages: {
					required: 'Last Name is required',
					minLength: 'Last Name must be at least 2 characters',
					maxLength: 'Last Name must be under 150 characters',
				},
			},
		},
	},
	ui: {
		'ui:rootFieldId': 'xo_rfq',
		subHeading: {
			'ui:widget': 'H4Widget',
			classNames: 'col-xs-12',
		},
		lightHeading: {
			'ui:widget': 'LightSubheadingWidget',
		},
		firstName: {
			'ui:widget': 'text',
			'ui:placeholder': 'First Name',
			classNames: 'col-sm-6',
		},
		lastName: {
			'ui:widget': 'text',
			'ui:placeholder': 'Last Name',
			classNames: 'col-sm-6',
		},
	},
};

export default nameStep1;
