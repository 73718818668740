const metadata = {
	name: 'Last Request',
	stepNumber: 0,
	progressDescription: 'Last Request',
	excludeFromStepCount: true,
	hideProgress: true,
	header: {
		title: 'Request Quote',
		titleClassName: 'rfq-use-previous-title',
		showFinish: false,
		showSend: false,
		showCancel: false,
		allowBack: false,
		showClose: false,
	},
	footer: {
		showNext: false,
		showSend: true,
	},
};

export default metadata;
