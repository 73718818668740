const property = {
	type: 'string',
};

const ui = {
	'ui:widget': 'VRMSelectionWidget',
	classNames: 'rfq-step col-xs-12',
};

const propertyName = 'VRMSelectionWidget';

module.exports = {
	property,
	ui,
	propertyName,
};
