import dateStep3 from '../shared_required_steps/dateStep3';
import emailStep2 from '../shared_required_steps/emailStep2';
import guestStep4 from '../shared_required_steps/guestStep4';
import nameStep1 from '../shared_required_steps/nameStep1';

const properties = require('../../properties');

// temporarily removed infavor of the video chat
const appointmentStep = {
	schema: {
		description: 'step 5',
		type: 'object',
		properties: {
			appointmentTimeline: {
				title: 'How soon would you like to come in for an appointment?',
				type: 'object',
				values: [
					{
						display: 'As soon as possible',
						value: 'As soon as possible',
					},
					{
						display: 'Within the next few weeks',
						value: 'Within the next few weeks',
					},
					{
						display: "I'm not sure yet",
						value: "I'm not sure yet",
					},
				],
				required: true,
				minProperties: 1,
			},
		},
	},
	ui: {
		'ui:rootFieldId': 'xo_rfq',
		appointmentTimeline: {
			'ui:field': 'xo_single_select_list',
			classNames: 'col-xs-12',
		},
	},
};

const BWP = {
	vendorCategory: 'BWP',
	tracking: properties.tracking.events.all,
	formName: 'BWP',
	steps: [
		nameStep1,
		emailStep2,
		dateStep3,
		guestStep4,
		{
			metaData: {
				name: 'Attire',
				stepNumber: 5,
				excludeFromStepCount: false,
				hideProgress: false,
				progressDescription: 'Attire',
				liveValidate: true,
				noHtml5Validate: true,
				showErrorList: false,
				header: {
					showClose: true,
				},
				footer: {
					nextButton: {
						text: 'Submit',
					},
				},
			},
			schema: {
				description: 'step 5',
				type: 'object',
				properties: {
					dressType: {
						title: 'What type of wedding attire are you shopping for?',
						type: 'array',
						values: [
							{ oldDisplay: 'Wedding gown', display: 'a wedding gown' },
							{
								oldDisplay: 'Bridesmaid dresses',
								display: 'bridesmaid dresses',
							},
							{
								oldDisplay: 'Mother of the bride dress',
								display: 'a mother of the bride dress',
							},
							{ oldDisplay: 'Suits or Tuxedos', display: 'suits or tuxedos' },
						],
						required: true,
						minProperties: 1,
						messages: {
							required: 'Please make a selection',
						},
					},
				},
			},
			ui: {
				'ui:rootFieldId': 'xo_rfq',
				dressType: {
					'ui:field': 'xo_multi_select_list',
					classNames: 'col-xs-12',
				},
			},
		},
		{
			metaData: {
				name: 'Budget',
				stepNumber: 6,
				excludeFromStepCount: false,
				hideProgress: false,
				progressDescription: 'Budget',
				liveValidate: true,
				noHtml5Validate: true,
				showErrorList: false,
				header: {
					showClose: true,
				},
				footer: {
					nextButton: {
						text: 'Submit',
					},
				},
			},
			schema: {
				description: 'step 6',
				type: 'object',
				properties: {
					dressBudget: {
						title: 'What is your wedding attire budget?',
						type: 'object',
						values: [
							{
								display: 'Over $5,000',
								value: 'Over $5,000',
							},
							{
								display: '$4,000 - $5,000',
								value: '$4,000 - $5,000',
							},
							{
								display: '$3,000 - $4,000',
								value: '$3,000 - $4,000',
							},
							{
								display: '$2,000 - $3,000',
								value: '$2,000 - $3,000',
							},
							{
								display: '$1,000 - $2,000',
								value: '$1,000 - $2,000',
							},
							{
								display: 'Under $1,000',
								value: 'Under $1,000',
							},
							{
								display: "I'm not sure yet",
								value: "I'm not sure yet",
							},
						],
						required: true,
						minProperties: 1,
					},
				},
			},
			ui: {
				'ui:rootFieldId': 'xo_rfq',
				dressBudget: {
					'ui:field': 'xo_single_select_list',
					classNames: 'col-xs-12',
				},
			},
		},
		{
			metaData: {
				name: 'Add a personal note',
				stepNumber: 7,
				excludeFromStepCount: false,
				hideProgress: false,
				progressDescription: 'Add a personal note',
				liveValidate: true,
				noHtml5Validate: true,
				showErrorList: false,
				header: {
					showClose: true,
				},
				footer: {
					nextButton: {
						text: 'Submit',
					},
				},
			},
			schema: {
				description: 'step 7',
				type: 'object',
				properties: {
					aboutHeading: properties.aboutYourWedding.property,
					bodyLabel: {},
					body: properties.body.property,
					bluecard: properties.bluecard.property,
					VRMSelectionWidget: properties.VRMSelectionWidget.property,
					legalCopy: properties.legalCopy.property,
				},
			},
			ui: {
				'ui:rootFieldId': 'xo_rfq',
				aboutHeading: properties.aboutYourWedding.optimizelyUi,
				bodyLabel: properties.bodyLabel.ui,
				body: properties.body.ui,
				bluecard: properties.bluecard.ui,
				VRMSelectionWidget: properties.VRMSelectionWidget.ui,
				legalCopy: properties.legalCopy.ui,
			},
		},
	],
	lastRequest: {
		metaData: properties.lastRequest.metadata,
		rows: [
			[
				{
					label: 'Name',
					type: 'literal',
					source: '${data.firstName} ${data.lastName}',
					id: 'rfq-summary-name',
				},
			],
			[
				{
					label: 'Email Address',
					type: 'literal',
					source: '${data.emailAddress}',
					id: 'rfq-summary-email',
				},
			],
			[
				{
					label: 'Wedding Date',
					type: 'literal',
					source: '${data.xo_date_picker.weddingDate}',
					id: 'rfq-summmary-wedding-date',
				},
			],
			[
				{
					label: 'Guest Count',
					type: 'literal',
					source: '${data.guest_count.display}',
					id: 'rfq-summmary-guest-count',
				},
			],
			[
				{
					label: 'Quick Note',
					type: 'literal',
					source: '${data.body}',
					id: 'rfq-summmary-about-your-wedding',
				},
			],
			[
				{
					label: 'Attach your style results to your message',
					type: 'bool',
					source: 'bluecard',
					id: 'rfq-summary-bluecard',
				},
			],
		],
	},
};

export default BWP;
