/* eslint-disable */

module.exports = {
	guestCount: require('./guestCount'),
	budget: require('./budget'),
	weddingTime: require('./weddingTime'),
	numberOfHours: require('./numberOfHours'),
	events: require('./events'),
	equipmentAndServices: require('./equipmentAndServices'),
	additionalServices: require('./additionalServices'),
	venuePicker: require('./venuePicker'),
	datePicker: require('./datePicker'),
	lastRequest: require('./lastRequest').default,
	lastRequestMessage: require('./lastRequestMessage').default,
	legalCopy: require('./legalCopy'),
	tracking: require('./tracking'),
	firstName: require('./firstName'),
	lastName: require('./lastName'),
	emailAddress: require('./emailAddress'),
	bluecard: require('./bluecard'),
	aboutYourWedding: require('./aboutYourWedding'),
	body: require('./body'),
	bodyLabel: require('./bodyLabel'),
	VRMSelectionWidget: require('./VRMSelectionWidget'),
};

/* eslint-enable */
