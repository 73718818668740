const property = {
	type: 'string',
	text: 'Describe your wedding vibe',
};

const ui = {
	'ui:widget': 'BluecardAwareH4Widget',
	classNames: 'col-xs-12',
	'ui:options': {
		bluecard: {
			'schema.text': 'Describe your wedding vibe',
		},
	},
};

const optimizelyUi = {
	'ui:widget': 'BluecardAwareH4Widget',
	classNames: 'col-xs-12',
	'ui:options': {
		bluecard: {
			'schema.text': 'Describe your wedding vibe',
		},
	},
};

const propertyName = 'aboutHeading';

module.exports = {
	property,
	ui,
	optimizelyUi,
	propertyName,
};
