import venueStep5 from '../required_steps/venueStep5';
import dateStep3 from '../shared_required_steps/dateStep3';
import emailStep2 from '../shared_required_steps/emailStep2';
import guestStep4 from '../shared_required_steps/guestStep4';
import nameStep1 from '../shared_required_steps/nameStep1';

const properties = require('../../properties');

const BEA = {
	vendorCategory: 'BEA',
	tracking: properties.tracking.events.all,
	formName: 'BEA',
	steps: [
		nameStep1,
		emailStep2,
		dateStep3,
		guestStep4,
		venueStep5,
		{
			metaData: {
				name: 'Services',
				stepNumber: 6,
				excludeFromStepCount: false,
				hideProgress: false,
				progressDescription: 'Services',
				liveValidate: true,
				noHtml5Validate: true,
				showErrorList: false,
				header: {
					showClose: true,
				},
			},
			schema: {
				description: 'step 6',
				type: 'object',
				properties: {
					howManyNeedServices: {
						title: 'How many people will need beauty services?',
						type: 'object',
						values: [
							{
								display: '1 - 5',
								value: {
									minNeedingServices: 1,
									maxNeedingServices: 5,
								},
							},
							{
								display: '6 - 10',
								value: {
									minNeedingServices: 6,
									maxNeedingServices: 10,
								},
							},
							{
								display: '11 - 15',
								value: {
									minNeedingServices: 11,
									maxNeedingServices: 15,
								},
							},
							{
								display: '16 - 20',
								value: {
									minNeedingServices: 16,
									maxNeedingServices: 20,
								},
							},
							{
								display: '20+',
								value: {
									minNeedingServices: 20,
									maxNeedingServices: 0,
								},
							},
						],
						required: true,
						minProperties: 1,
						messages: {
							required: 'Please select how many people need services',
						},
					},
				},
			},
			ui: {
				'ui:rootFieldId': 'xo_rfq',
				howManyNeedServices: {
					'ui:field': 'xo_single_select_list',
					classNames: 'col-xs-12',
				},
			},
		},
		{
			metaData: {
				name: 'Duration',
				stepNumber: 7,
				excludeFromStepCount: false,
				hideProgress: false,
				progressDescription: 'Duration',
				liveValidate: true,
				noHtml5Validate: true,
				showErrorList: false,
				header: {
					showClose: true,
				},
			},
			schema: {
				description: 'step 7',
				type: 'object',
				properties: {
					subHeading: {
						type: 'string',
						label:
							'What time will the beauty professional need to be finished?',
						text: 'What time will the beauty professional need to be finished?',
					},
					end_time_picker: {
						type: 'object',
						required: true,
						properties: {
							has_selection: {
								type: 'bool',
								label: "I'm Not Sure Yet",
								required: true,
							},
							endTime: {
								type: 'object',
								values: properties.weddingTime.property.values,
								required: true,
								minProperties: 1,
							},
						},
					},
				},
			},
			ui: {
				'ui:rootFieldId': 'xo_rfq',
				classNames: 'col-xs-12',
				location: {
					'ui:field': 'xo_single_select_list',
				},
				subHeading: {
					'ui:widget': 'SubheadingWidget',
				},
				end_time_picker: {
					'ui:field': 'xo_radio_drop_down',
					'ui:placeholder': 'Finish Time',
				},
			},
		},
		{
			metaData: {
				name: 'Budget',
				stepNumber: 8,
				excludeFromStepCount: false,
				hideProgress: false,
				progressDescription: 'Budget',
				liveValidate: true,
				noHtml5Validate: true,
				showErrorList: false,
				header: {
					showClose: true,
				},
			},
			schema: {
				description: 'step 8',
				type: 'object',
				properties: {
					budget: {
						...properties.budget.property,
						title: 'What is your estimated budget for beauty services?',
						values: [
							{
								display: 'Over $1,500',
								value: {
									minBudget: 1500,
									maxBudget: 0,
								},
							},
							{
								display: '$1,250 - $1,500',
								value: {
									minBudget: 1250,
									maxBudget: 1500,
								},
							},
							{
								display: '$1,000 - $1,250',
								value: {
									minBudget: 1000,
									maxBudget: 1250,
								},
							},
							{
								display: '$750 - $1,000',
								value: {
									minBudget: 750,
									maxBudget: 1000,
								},
							},
							{
								display: '$500 - $750',
								value: {
									minBudget: 500,
									maxBudget: 750,
								},
							},
							{
								display: 'Less than $500',
								value: {
									minBudget: 1,
									maxBudget: 500,
								},
							},
							{
								display: "I'm not sure yet",
								value: {
									minBudget: 0,
									maxBudget: 0,
								},
							},
						],
					},
				},
			},
			ui: {
				'ui:rootFieldId': 'xo_rfq',
				budget: {
					...properties.budget.ui,
					classNames: 'col-xs-12',
				},
			},
		},
		{
			metaData: {
				name: 'Add a personal note',
				stepNumber: 9,
				excludeFromStepCount: false,
				hideProgress: false,
				progressDescription: 'Add a personal note',
				liveValidate: true,
				noHtml5Validate: true,
				showErrorList: false,
				header: {
					showClose: true,
				},
				footer: {
					nextButton: {
						text: 'Submit',
					},
				},
			},
			schema: {
				description: 'step 9',
				type: 'object',
				properties: {
					aboutHeading: properties.aboutYourWedding.property,
					bodyLabel: properties.bodyLabel.property,
					body: properties.body.property,
					bluecard: properties.bluecard.property,
					VRMSelectionWidget: properties.VRMSelectionWidget.property,
					legalCopy: properties.legalCopy.property,
				},
			},
			ui: {
				'ui:rootFieldId': 'xo_rfq',
				aboutHeading: properties.aboutYourWedding.ui,
				bodyLabel: properties.bodyLabel.ui,
				body: {
					...properties.body.ui,
					'ui:placeholder':
						'Hello! My partner and I are getting married and we’re interested in learning more about pricing and services.',
				},
				bluecard: properties.bluecard.ui,
				VRMSelectionWidget: properties.VRMSelectionWidget.ui,
				legalCopy: properties.legalCopy.ui,
			},
		},
	],
	lastRequest: {
		metaData: properties.lastRequest.metadata,
		rows: [
			[
				{
					label: 'Name',
					type: 'literal',
					source: '${data.firstName} ${data.lastName}',
					id: 'rfq-summary-name',
				},
				{
					label: 'Email Address',
					type: 'literal',
					source: '${data.emailAddress}',
					id: 'rfq-summary-email',
				},
			],
			[
				{
					label: 'Wedding Date',
					type: 'literal',
					source: '${data.xo_date_picker.weddingDate}',
					id: 'rfq-summmary-wedding-date',
				},
				{
					label: 'Guest Count',
					type: 'literal',
					source: '${data.guest_count.display}',
					id: 'rfq-summmary-guest-count',
				},
			],
			[
				{
					label: 'Number of people needing services',
					type: 'literal',
					source: '${data.howManyNeedServices.display}',
					id: 'rfq-summary-how-many-need-services',
				},
				{
					label: 'Budget',
					type: 'literal',
					source: '${data.budget.display}',
					id: 'rfq-summmary-budget',
				},
			],
			[
				{
					label: 'Finish Time',
					type: 'endTime',
					source: 'end_time_picker',
					id: 'rfq-summary-finish-time',
				},
			],
			[
				{
					label: 'Quick Note',
					type: 'literal',
					source: '${data.body}',
					id: 'rfq-summmary-about-your-wedding',
				},
			],
			[
				{
					label: 'Attach your style results to your message',
					type: 'bool',
					source: 'bluecard',
					id: 'rfq-summary-bluecard',
				},
			],
		],
	},
};

export default BEA;
