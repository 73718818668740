const properties = require('../../properties');

const emailStep2 = {
	metaData: {
		name: 'Email',
		stepNumber: 2,
		excludeFromStepCount: false,
		hideProgress: false,
		progressDescription: 'Email',
		liveValidate: true,
		noHtml5Validate: true,
		showErrorList: false,
		header: {
			title: "What's your email?",
			showClose: true,
			className: 'xo-header modal-header header previous',
			icon: true,
		},
	},
	schema: {
		description: 'step 2',
		type: 'object',
		properties: {
			emailAddress: properties.emailAddress.property,
			subHeading: {
				type: 'string',
				label: "What's your phone number?",
				text: "What's your phone number?",
			},
			phoneNumber: {
				type: 'string',
				label: 'Phone Number (Optional)',
				required: false,
				format: 'phone-us',
				messages: {
					format: 'Phone Number must have no more or less than 10 numbers',
				},
			},
		},
	},
	ui: {
		'ui:rootFieldId': 'xo_rfq',
		emailAddress: properties.emailAddress.ui,
		subHeading: {
			'ui:widget': 'H4Widget',
			classNames: 'col-xs-12 phone-number-heading',
		},
		phoneNumber: {
			'ui:widget': 'PhoneWidget',
			'ui:placeholder': 'Phone Number (Optional)',
			classNames: 'col-sm-12',
		},
	},
};

export default emailStep2;
