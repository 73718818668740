const property = {
	type: 'string',
	label: 'First Name',
	required: true,
	maxLength: 149,
	minLength: 2,
	messages: {
		required: 'First Name is required',
		minLength: 'First Name must be at least 2 characters',
		maxLength: 'First Name must be under 150 characters',
	},
};

const ui = {
	'ui:widget': 'text',
	'ui:placeholder': 'First Name',
	classNames: 'col-xs-12 col-sm-6',
};

const propertyName = 'firstName';

const firstName = {
	property,
	ui,
	propertyName,
};

export default firstName;
