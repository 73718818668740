import BDS from './BDS/index';
import BEA from './BEA/index';
import BWP from './BWP/index';
import CER from './CER/index';
import DJS from './DJS/index';
import ENS from './ENS/index';
import FLO from './FLO/index';
import REC from './REC/index';
import VID from './VID/index';
import WPH from './WPH/index';

import ENHANCED_RFQ_OPT_OUT from './ENHANCED_RFQ_OPT_OUT/index';
// Non category specific situations:
import HAS_START_TIME_AND_DURATION from './HAS_START_TIME_AND_DURATION/index';
import PAID_MESSAGE from './PAID_MESSAGE/index';
import UNPAID_MESSAGE from './UNPAID/MESSAGE/index';
import UNPAID_RFQ from './UNPAID/RFQ/index';
import WITHOUT_TIME_AND_DURATION from './WITHOUT_TIME_AND_DURATION/index';
// Maybe add RFQ/Message folder structure for clarity

const CATEGORIES_WITH_HOURS = [
	'BAR', // Bar Services
	'PHB', // Photo Booths
	'SVS', // Service Staff
	'LIT', // Lighting
	'TEC', // Technology
	'TRA', // Transportation
	'DES', // Wedding Designers
	'VAR', // Variety Acts
	'CAT', // Caterers
	'OPC', // Officiants + Premarital Counseling
	'DJS', // DJs
	'BEA', // Beauty Services
	'ENS', // Ensembles & Soloists
	'WPH', // Photographers
	'VID', // Videographers
	'RNT', // Rentals
	'PLN', // Wedding Planners
];

const categoryHasHours = (categoryCode) =>
	CATEGORIES_WITH_HOURS.indexOf(categoryCode) > -1;

const CONFIGURATIONS = {
	PAID: {
		RFQ: {
			OPT_IN: {
				// Opt in is determined by API: vendor.settings.enhancedRFQ = true
				default: WITHOUT_TIME_AND_DURATION,
				REC,
				CER,
				FLO,
				BDS,
				BWP,
				WITH_HOURS: {
					default: HAS_START_TIME_AND_DURATION,
					DJS,
					BEA,
					ENS,
					WPH,
					VID,
				},
			},
			OPT_OUT: {
				// Opt out is determined by API: vendor.settings.enhancedRFQ = false
				default: ENHANCED_RFQ_OPT_OUT,
				WITH_HOURS: {
					default: ENHANCED_RFQ_OPT_OUT,
				},
			},
		},
		MESSAGE: {
			default: PAID_MESSAGE,
		},
	},
	UNPAID: {
		RFQ: {
			default: UNPAID_RFQ,
			WITH_HOURS: {
				default: UNPAID_RFQ,
			},
		},
		MESSAGE: {
			default: UNPAID_MESSAGE,
		},
	},
};

export { CONFIGURATIONS, categoryHasHours, CATEGORIES_WITH_HOURS };
