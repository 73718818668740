const property = {
	type: 'string',
	required: true,
	minLength: 1,
	label: 'Briefly describe your dream wedding (required)',
	messages: {
		required: 'needs to be filled. Please provide a message',
		minLength: 'needs to be filled. Please provide a message',
	},
};

const ui = {
	'ui:widget': 'BluecardAwareTextareaWidget',
	classNames: 'rfq-step notes-step col-xs-12',
	'ui:placeholder':
		'Hello! My partner and I are getting married and we’re interested in learning more about pricing and services.',
};

const propertyName = 'body';

module.exports = {
	property,
	ui,
	propertyName,
};
