const properties = require('../../properties');

const dateStep3 = {
	metaData: {
		name: 'Wedding Date',
		stepNumber: 3,
		excludeFromStepCount: false,
		hideProgress: false,
		progressDescription: 'Wedding Date',
		liveValidate: true,
		noHtml5Validate: true,
		showErrorList: false,
		header: {
			title: "What's your wedding date?",
			showClose: true,
			className: 'xo-header modal-header header previous',
			icon: true,
		},
	},
	schema: {
		description: 'step 2',
		type: 'object',
		properties: {
			xo_date_picker: properties.datePicker.property,
			flexibleWeddingDate: {
				label: 'My wedding date is flexible',
				type: 'boolean',
			},
		},
	},
	ui: {
		'ui:rootFieldId': 'xo_rfq',
		xo_date_picker: properties.datePicker.optimizelyUi,
		flexibleWeddingDate: {
			classNames: 'col-sm-12 sub-select',
			'ui:field': 'xo_sub_select',
			'ui:options': 'custom',
		},
	},
};

export default dateStep3;
