const property = {
	type: 'object',
	required: true,
	minProperties: 1,
	messages: {
		required: 'Please provide the estimated budget',
	},
};

const ui = {
	'ui:field': 'xo_single_select_list',
};

module.exports = {
	property,
	ui,
};
