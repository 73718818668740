const property = {
	label: 'Number of Hours',
	type: 'object',
	values: [
		{
			display: '1 hour',
			value: 1,
		},
		{
			display: '1.5 hours',
			value: 1.5,
		},
		{
			display: '2 hours',
			value: 2,
		},
		{
			display: '2.5 hours',
			value: 2.5,
		},
		{
			display: '3 hours',
			value: 3,
		},
		{
			display: '3.5 hours',
			value: 3.5,
		},
		{
			display: '4 hours',
			value: 4,
		},
		{
			display: '4.5 hours',
			value: 4.5,
		},
		{
			display: '5 hours',
			value: 5,
		},
		{
			display: '5.5 hours',
			value: 5.5,
		},
		{
			display: '6 hours',
			value: 6,
		},
		{
			display: '6.5 hours',
			value: 6.5,
		},
		{
			display: '7 hours',
			value: 7,
		},
		{
			display: '7.5 hours',
			value: 7.5,
		},
		{
			display: '8 hours',
			value: 8,
		},
		{
			display: '8.5 hours',
			value: 8.5,
		},
		{
			display: '9 hours',
			value: 9,
		},
		{
			display: '9.5 hours',
			value: 9.5,
		},
		{
			display: '10+ hours',
			value: 10,
		},
	],
	required: true,
	minProperties: 1,
	messages: {
		required: 'Please provide the number of hours needed',
	},
};

const ui = {
	'ui:field': 'xo_drop_down',
	'ui:placeholder': 'Number of Hours',
	classNames: 'col-xs-12 col-md-6',
};

module.exports = {
	property,
	ui,
};
