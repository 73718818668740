const property = {
	type: 'string',
	label: '',
};

const ui = {
	'ui:widget': 'H5Widget',
	classNames: 'col-xs-12',
	'ui:options': {
		bluecard: {
			'schema.text': 'Quick Note',
		},
	},
};

const propertyName = 'bodyLabel';

module.exports = {
	property,
	ui,
	propertyName,
};
