const properties = {
	name: {
		label: 'Name',
		type: 'literal',
		source: '${data.firstName} ${data.lastName}',
		id: 'rfq-summary-name',
	},
	email: {
		label: 'Email Address',
		type: 'literal',
		source: '${data.emailAddress}',
		id: 'rfq-summary-email',
	},
	weddingDate: {
		label: 'Wedding Date',
		type: 'literal',
		source: '${data.xo_date_picker.weddingDate}',
		id: 'rfq-summmary-wedding-date',
	},
	guestCount: {
		label: 'Guest Count',
		type: 'literal',
		source: '${data.guest_count.display}',
		id: 'rfq-summmary-guest-count',
	},
	venue: {
		label: 'Venue',
		type: 'venue',
		source: 'venue_picker.receptionVenue',
		id: 'rfq-summmary-venue',
	},
	numberOfHours: {
		label: 'Number of Hours',
		type: 'literal',
		source: '${data.numberOfHours.display}',
		id: 'rfq-summmary-number-of-hours',
	},
	servicesNeeded: {
		label: 'Services needed at separate ceremony venue',
		type: 'bool',
		source: 'venue_picker.requireCeremonyVenue',
		id: 'rfq-summmary-wedding-date',
	},
	startTime: {
		label: 'Start Time',
		type: 'literal',
		source: '${data.weddingTime.display}',
		id: 'rfq-summmary-start-time',
	},
	budget: {
		label: 'Budget',
		type: 'literal',
		source: '${data.budget.display}',
		id: 'rfq-summmary-budget',
	},
	additionalServices: {
		label: 'Additional Services Required',
		type: 'array',
		source: 'equipmentAndServices',
		id: 'rfq-summmary-equipment-or-services',
	},
	servicesRequired: {
		label: 'Services Required',
		type: 'array',
		source: 'events',
		id: 'rfq-summmary-events',
	},
	about: {
		label: 'Quick Note',
		type: 'literal',
		source: '${data.body}',
		id: 'rfq-summmary-about-your-wedding',
	},
	bluecard: {
		label: 'Attach your style results to your message',
		type: 'bool',
		source: 'bluecard',
		id: 'rfq-summary-bluecard',
	},
};

export default properties;
