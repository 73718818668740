import venueStep5 from '../required_steps/venueStep5';
import dateStep3 from '../shared_required_steps/dateStep3';
import emailStep2 from '../shared_required_steps/emailStep2';
import guestStep4 from '../shared_required_steps/guestStep4';
import nameStep1 from '../shared_required_steps/nameStep1';

const properties = require('../../properties');

// Required 5 step fields
// Plus Start Time and Duration
// CAT, TRA, PLN, PHB categories

const HAS_START_TIME_AND_DURATION = {
	vendorCategory: 'HAS_START_TIME_AND_DURATION',
	formName: 'HAS_START_TIME_AND_DURATION',
	tracking: properties.tracking.events.all,
	steps: [
		nameStep1,
		emailStep2,
		dateStep3,
		guestStep4,
		venueStep5,
		{
			metaData: {
				name: 'Duration',
				stepNumber: 6,
				excludeFromStepCount: false,
				hideProgress: false,
				progressDescription: 'Duration',
				liveValidate: true,
				noHtml5Validate: true,
				showErrorList: false,
				header: {
					showClose: true,
					title: 'When and how long is your event?',
				},
			},
			schema: {
				description: 'step 6',
				type: 'object',
				properties: {
					weddingTime: {
						...properties.weddingTime.property,
						label: 'Event Start Time',
					},
					numberOfHours: properties.numberOfHours.property,
				},
			},
			ui: {
				'ui:rootFieldId': 'xo_rfq',
				weddingTime: properties.weddingTime.ui,
				subHeading: {
					'ui:widget': 'H5Widget',
				},
				numberOfHours: properties.numberOfHours.ui,
			},
		},
		{
			metaData: {
				name: 'Add a personal note',
				stepNumber: 7,
				excludeFromStepCount: false,
				hideProgress: false,
				progressDescription: 'Add a personal note',
				liveValidate: true,
				noHtml5Validate: true,
				showErrorList: false,
				header: {
					showClose: true,
				},
				footer: {
					nextButton: {
						text: 'Submit',
					},
				},
			},
			schema: {
				description: 'step 7',
				type: 'object',
				properties: {
					aboutHeading: properties.aboutYourWedding.property,
					bodyLabel: {},
					body: properties.body.property,
					bluecard: properties.bluecard.property,
					VRMSelectionWidget: properties.VRMSelectionWidget.property,
					legalCopy: properties.legalCopy.property,
				},
			},
			ui: {
				'ui:rootFieldId': 'xo_rfq',
				aboutHeading: properties.aboutYourWedding.ui,
				bodyLabel: properties.bodyLabel.ui,
				body: properties.body.ui,
				bluecard: properties.bluecard.ui,
				VRMSelectionWidget: properties.VRMSelectionWidget.ui,
				legalCopy: properties.legalCopy.ui,
			},
		},
	],
	lastRequest: {
		metaData: properties.lastRequest.metadata,
		rows: [
			[
				{
					label: 'Name',
					type: 'literal',
					source: '${data.firstName} ${data.lastName}',
					id: 'rfq-summary-name',
				},
				{
					label: 'Email Address',
					type: 'literal',
					source: '${data.emailAddress}',
					id: 'rfq-summary-email',
				},
			],
			[
				{
					label: 'Wedding Date',
					type: 'literal',
					source: '${data.xo_date_picker.weddingDate}',
					id: 'rfq-summmary-wedding-date',
				},
				{
					label: 'Guest Count',
					type: 'literal',
					source: '${data.guest_count.display}',
					id: 'rfq-summmary-guest-count',
				},
			],
			[
				{
					label: 'Venue',
					type: 'venue',
					source: 'venue_picker.receptionVenue',
					id: 'rfq-summmary-venue',
				},
				{
					label: 'Start Time',
					type: 'literal',
					source: '${data.weddingTime.display}',
					id: 'rfq-summmary-start-time',
				},
			],
			[
				{
					label: 'Number of Hours',
					type: 'literal',
					source: '${data.numberOfHours.display}',
					id: 'rfq-summmary-number-of-hours',
				},
			],
			[
				{
					label: 'Quick Note',
					type: 'literal',
					source: '${data.body}',
					id: 'rfq-summmary-about-your-wedding',
				},
			],
			[
				{
					label: 'Attach your style results to your message',
					type: 'bool',
					source: 'bluecard',
					id: 'rfq-summary-bluecard',
				},
			],
		],
	},
};

export default HAS_START_TIME_AND_DURATION;
