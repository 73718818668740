import venueStep5 from '../required_steps/venueStep5';
import dateStep3 from '../shared_required_steps/dateStep3';
import emailStep2 from '../shared_required_steps/emailStep2';
import guestStep4 from '../shared_required_steps/guestStep4';
import nameStep1 from '../shared_required_steps/nameStep1';

const properties = require('../../properties');

const FLO = {
	vendorCategory: 'FLO',
	tracking: properties.tracking.events.all,
	formName: 'FLO',
	steps: [
		nameStep1,
		emailStep2,
		dateStep3,
		guestStep4,
		venueStep5,
		{
			metaData: {
				name: 'Events',
				stepNumber: 6,
				excludeFromStepCount: false,
				hideProgress: false,
				progressDescription: 'Events',
				liveValidate: true,
				noHtml5Validate: true,
				showErrorList: false,
				header: {
					showClose: true,
				},
			},
			schema: {
				description: 'step 6',
				type: 'object',
				properties: {
					events: {
						...properties.events.property,
						title: 'Which events would you like flowers for?',
						values: [
							{
								display: 'Ceremony',
								value: { name: 'Ceremony' },
							},
							{
								display: 'Reception',
								value: { name: 'Reception' },
							},
						],
						required: true,
						minProperties: 1,
					},
				},
			},
			ui: {
				'ui:rootFieldId': 'xo_rfq',
				events: properties.events.ui,
			},
		},
		{
			metaData: {
				name: 'Arrangements',
				stepNumber: 7,
				excludeFromStepCount: false,
				hideProgress: false,
				progressDescription: 'Arrangements',
				liveValidate: true,
				noHtml5Validate: true,
				showErrorList: false,
				header: {
					showClose: true,
				},
			},
			schema: {
				description: 'step 7',
				type: 'object',
				properties: {
					equipmentAndServices: {
						...properties.equipmentAndServices.property,
						title: 'Which floral arrangements are you interested in?',
						values: [
							{
								display: 'Aisle decorations',
								value: { name: 'Aisle decorations' },
							},
							{
								display: 'Bride bouquet',
								value: { name: 'Bride bouquet' },
							},
							{
								display: "Bridesmaids' bouquets",
								value: { name: "Bridesmaids' bouquets" },
							},
							{
								display: 'Boutonnieres',
								value: { name: 'Boutonnieres' },
							},
							{
								display: 'Corsages',
								value: { name: 'Corsages' },
							},
							{
								display: 'Flowers for cake',
								value: { name: 'Flowers for cake' },
							},
							{
								display: 'Table arrangements',
								value: { name: 'Table arrangements' },
							},
							{
								display: 'As recommended by the vendor (send me options)',
								value: {
									name: 'As recommended by the vendor (send me options)',
								},
							},
						],
					},
				},
			},
			ui: {
				'ui:rootFieldId': 'xo_rfq',
				equipmentAndServices: properties.equipmentAndServices.ui,
			},
		},
		{
			metaData: {
				name: 'Budget',
				stepNumber: 8,
				excludeFromStepCount: false,
				hideProgress: false,
				progressDescription: 'Budget',
				liveValidate: true,
				noHtml5Validate: true,
				showErrorList: false,
				header: {
					showClose: true,
				},
			},
			schema: {
				description: 'step 8',
				type: 'object',
				properties: {
					budget: {
						...properties.budget.property,
						title: 'What is your estimated budget for floral services?',
						values: [
							{
								display: 'Over $5,000',
								value: {
									minBudget: 5000,
									maxBudget: 0,
								},
							},
							{
								display: '$4,000 - $5,000',
								value: {
									minBudget: 4000,
									maxBudget: 5000,
								},
							},
							{
								display: '$3,000 - $4,000',
								value: {
									minBudget: 3000,
									maxBudget: 4000,
								},
							},
							{
								display: '$2,000 - $3,000',
								value: {
									minBudget: 2000,
									maxBudget: 3000,
								},
							},
							{
								display: '$1,500 - $2,000',
								value: {
									minBudget: 1500,
									maxBudget: 2000,
								},
							},
							{
								display: 'Less than $1,500',
								value: {
									minBudget: 1,
									maxBudget: 1500,
								},
							},
							{
								display: "I'm not sure yet",
								value: {
									minBudget: 0,
									maxBudget: 0,
								},
							},
						],
					},
				},
			},
			ui: {
				'ui:rootFieldId': 'xo_rfq',
				budget: properties.budget.ui,
			},
		},
		{
			metaData: {
				name: 'Quick Note',
				stepNumber: 9,
				excludeFromStepCount: false,
				hideProgress: false,
				progressDescription: 'Add a personal note',
				liveValidate: true,
				noHtml5Validate: true,
				showErrorList: false,
				header: {
					showClose: true,
				},
				footer: {
					nextButton: {
						text: 'Submit',
					},
				},
			},
			schema: {
				description: 'step 9',
				type: 'object',
				properties: {
					aboutHeading: properties.aboutYourWedding.property,
					bodyLabel: {},
					body: properties.body.property,
					bluecard: properties.bluecard.property,
					VRMSelectionWidget: properties.VRMSelectionWidget.property,
					legalCopy: properties.legalCopy.property,
				},
			},
			ui: {
				'ui:rootFieldId': 'xo_rfq',
				aboutHeading: properties.aboutYourWedding.ui,
				bodyLabel: properties.bodyLabel.ui,
				body: properties.body.ui,
				bluecard: properties.bluecard.ui,
				VRMSelectionWidget: properties.VRMSelectionWidget.ui,
				legalCopy: properties.legalCopy.ui,
			},
		},
	],
	lastRequest: {
		metaData: properties.lastRequest.metadata,
		rows: [
			[
				{
					label: 'Name',
					type: 'literal',
					source: '${data.firstName} ${data.lastName}',
					id: 'rfq-summary-name',
				},
				{
					label: 'Email Address',
					type: 'literal',
					source: '${data.emailAddress}',
					id: 'rfq-summary-email',
				},
			],
			[
				{
					label: 'Wedding Date',
					type: 'literal',
					source: '${data.xo_date_picker.weddingDate}',
					id: 'rfq-summmary-wedding-date',
				},
				{
					label: 'Guest Count',
					type: 'literal',
					source: '${data.guest_count.display}',
					id: 'rfq-summmary-guest-count',
				},
			],
			[
				{
					label: 'Venue',
					type: 'venue',
					source: 'venue_picker.receptionVenue',
					id: 'rfq-summmary-venue',
				},
			],
			[
				{
					label: 'Services needed at separate ceremony venue',
					type: 'bool',
					source: 'venue_picker.requireCeremonyVenue',
					id: 'rfq-summmary-wedding-date',
				},
			],
			[
				{
					label: 'Events to Decorate',
					type: 'array',
					source: 'events',
					id: 'rfq-summmary-events',
				},
				{
					label: 'Budget',
					type: 'literal',
					source: '${data.budget.display}',
					id: 'rfq-summmary-budget',
				},
			],
			[
				{
					label: 'Additional Decorations',
					type: 'array',
					source: 'equipmentAndServices',
					id: 'rfq-summmary-equipment-or-services',
				},
			],
			[
				{
					label: 'Quick Note',
					type: 'literal',
					source: '${data.body}',
					id: 'rfq-summmary-about-your-wedding',
				},
			],
			[
				{
					label: 'Attach your style results to your message',
					type: 'bool',
					source: 'bluecard',
					id: 'rfq-summary-bluecard',
				},
			],
		],
	},
};

export default FLO;
