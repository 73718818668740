import venueStep5 from '../required_steps/venueStep5';
import dateStep3 from '../shared_required_steps/dateStep3';
import emailStep2 from '../shared_required_steps/emailStep2';
import guestStep4 from '../shared_required_steps/guestStep4';
import nameStep1 from '../shared_required_steps/nameStep1';

const properties = require('../../properties');

const WPH = {
	vendorCategory: 'WPH',
	tracking: properties.tracking.events.all,
	formName: 'WPH',
	steps: [
		nameStep1,
		emailStep2,
		dateStep3,
		guestStep4,
		venueStep5,
		{
			metaData: {
				stepNumber: 6,
				name: 'Duration',
				excludeFromStepCount: false,
				hideProgress: false,
				progressDescription: 'Duration',
				liveValidate: true,
				noHtml5Validate: true,
				showErrorList: false,
				header: {
					showClose: true,
					title: 'When and how long do you need wedding photography?',
				},
			},
			schema: {
				description: 'step 6',
				type: 'object',
				properties: {
					weddingTime: {
						...properties.weddingTime.property,
						label: 'Event Start Time',
						messages: {
							required: 'Please provide the event start time',
						},
					},
					numberOfHours: properties.numberOfHours.property,
				},
			},
			ui: {
				'ui:rootFieldId': 'xo_rfq',
				subHeading: {
					'ui:widget': 'SubheadingWidget',
					classNames: 'col-xs-12',
				},
				weddingTime: properties.weddingTime.ui,
				numberOfHours: properties.numberOfHours.ui,
			},
		},
		{
			metaData: {
				stepNumber: 7,
				name: 'Additional Services',
				excludeFromStepCount: false,
				hideProgress: false,
				progressDescription: 'Additional Services',
				liveValidate: true,
				noHtml5Validate: true,
				showErrorList: false,
				header: {
					showClose: true,
				},
			},
			schema: {
				description: 'step 7',
				type: 'object',
				properties: {
					additionalServices: {
						...properties.additionalServices.property,
						title: 'Which additional services are you interested in?',
						values: [
							{
								display:
									'Second Photographer (another photographer to provide additional coverage on your big day)',
								value: { name: 'Second Photographer' },
							},
							{
								display:
									"Nondigital Products (wedding album, parent's album, heirloom boxes, prints, etc.)",
								value: { name: 'Nondigital Products' },
							},
							{
								display: 'Engagement Session',
								value: { name: 'Engagement Session' },
							},
						],
					},
				},
			},
			ui: {
				'ui:rootFieldId': 'xo_rfq',
				additionalServices: {
					...properties.additionalServices.ui,
					'ui:styleOptions': [['w', /\(([^\)]+)\)/, 'sub-label-style']],
					classNames: 'sub-labels',
				},
			},
		},
		{
			metaData: {
				stepNumber: 8,
				name: 'Budget',
				excludeFromStepCount: false,
				hideProgress: false,
				progressDescription: 'Budget',
				liveValidate: true,
				noHtml5Validate: true,
				showErrorList: false,
				header: {
					showClose: true,
				},
			},
			schema: {
				description: 'step 8',
				type: 'object',
				properties: {
					budget: {
						...properties.budget.property,
						title: 'What is your estimated budget for photography services?',
						values: [
							{
								display: 'Over $7,000',
								value: {
									minBudget: 7000,
									maxBudget: 0,
								},
							},
							{
								display: '$6,000 - $7,000',
								value: {
									minBudget: 6000,
									maxBudget: 7000,
								},
							},
							{
								display: '$5,000 - $6,000',
								value: {
									minBudget: 5000,
									maxBudget: 6000,
								},
							},
							{
								display: '$4,000 - $5,000',
								value: {
									minBudget: 4000,
									maxBudget: 5000,
								},
							},
							{
								display: '$3,000 - $4,000',
								value: {
									minBudget: 3000,
									maxBudget: 4000,
								},
							},
							{
								display: '$2,000 - $3,000',
								value: {
									minBudget: 2000,
									maxBudget: 3000,
								},
							},
							{
								display: 'Less than $2,000',
								value: {
									minBudget: 1,
									maxBudget: 2000,
								},
							},
							{
								display: "I'm not sure yet",
								value: {
									minBudget: 0,
									maxBudget: 0,
								},
							},
						],
					},
				},
			},
			ui: {
				'ui:rootFieldId': 'xo_rfq',
				budget: properties.budget.ui,
			},
		},
		{
			metaData: {
				name: 'Quick Note',
				stepNumber: 9,
				excludeFromStepCount: false,
				hideProgress: false,
				progressDescription: 'Add a personal note',
				liveValidate: true,
				noHtml5Validate: true,
				showErrorList: false,
				header: {
					showClose: true,
				},
				footer: {
					nextButton: {
						text: 'Submit',
					},
				},
			},
			schema: {
				description: 'step 9',
				type: 'object',
				properties: {
					aboutHeading: properties.aboutYourWedding.property,
					bodyLabel: {},
					body: properties.body.property,
					bluecard: properties.bluecard.property,
					VRMSelectionWidget: properties.VRMSelectionWidget.property,
					legalCopy: properties.legalCopy.property,
				},
			},
			ui: {
				'ui:rootFieldId': 'xo_rfq',
				aboutHeading: properties.aboutYourWedding.ui,
				bodyLabel: properties.bodyLabel.ui,
				body: properties.body.ui,
				bluecard: properties.bluecard.ui,
				VRMSelectionWidget: properties.VRMSelectionWidget.ui,
				legalCopy: properties.legalCopy.ui,
			},
		},
	],
	lastRequest: {
		metaData: properties.lastRequest.metadata,
		rows: [
			[
				{
					label: 'Name',
					type: 'literal',
					source: '${data.firstName} ${data.lastName}',
					id: 'rfq-summary-name',
				},
				{
					label: 'Email Address',
					type: 'literal',
					source: '${data.emailAddress}',
					id: 'rfq-summary-email',
				},
			],
			[
				{
					label: 'Wedding Date',
					type: 'literal',
					source: '${data.xo_date_picker.weddingDate}',
					id: 'rfq-summmary-wedding-date',
				},
				{
					label: 'Guest Count',
					type: 'literal',
					source: '${data.guest_count.display}',
					id: 'rfq-summmary-guest-count',
				},
			],
			[
				{
					label: 'Venue',
					type: 'venue',
					source: 'venue_picker.receptionVenue',
					id: 'rfq-summmary-venue',
				},
			],
			[
				{
					label: 'Event Start Time',
					type: 'literal',
					source: '${data.weddingTime.display}',
					id: 'rfq-summmary-start-time',
				},
				{
					label: 'Number of Hours',
					type: 'literal',
					source: '${data.numberOfHours.display}',
					id: 'rfq-summmary-number-of-hours',
				},
			],
			[
				{
					label: 'Services needed at separate ceremony venue',
					type: 'bool',
					source: 'venue_picker.requireCeremonyVenue',
					id: 'rfq-summmary-wedding-date',
				},
			],
			[
				{
					label: 'Budget',
					type: 'literal',
					source: '${data.budget.display}',
					id: 'rfq-summmary-budget',
				},
			],
			[
				{
					label: 'Additional Services',
					type: 'array',
					source: 'additionalServices',
					id: 'rfq-summary-additional-services',
				},
			],
			[
				{
					label: 'Quick Note',
					type: 'literal',
					source: '${data.body}',
					id: 'rfq-summmary-about-your-wedding',
				},
			],
			[
				{
					label: 'Attach your style results to your messagel',
					type: 'bool',
					source: 'bluecard',
					id: 'rfq-summary-bluecard',
				},
			],
		],
	},
};

export default WPH;
